<template>
  <div class="container">
    <LiLoading class="LiLoading" v-show="isLoading"></LiLoading>
    <iframe
      src="https://blog.litely.life"
      class="iframe"
      @load="onLoad"
      frameBorder="0"
    ></iframe>
  </div>
</template>

<script>
import LiLoading from "@/components/li_loading";
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    this.$store.commit("setIsApp", false);
  },
  mounted() {
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 3000);
  },
  components: {
    LiLoading,
  },
  computed: {},
  methods: {
    onLoad() {
      this.isLoading = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 100%;
  height: 100vh;
  position: relative;

  .LiLoading {
    position: absolute;
    top: 0;
    left: 0;
  }

  .iframe {
    width: 100%;
    height: 100%;
    // margin-top: 84px;
  }
}
</style>
