<template>
  <div class="loading_container">
    <pulse-loader :loading="isLoading"></pulse-loader>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {},
  components: {
    PulseLoader,
  },
  computed: {},
  methods: {},
};
</script>
<style lang='less' scoped>
.loading_container {
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(253, 250, 250, 0.3);
}
</style>